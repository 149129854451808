import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { GoogleLogin } from '@react-oauth/google';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import application_properties from '../../applicationProperties';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';
import logo from '../../assets/img/logos/nyu-short-color.png';
import SpinnerModal from '../common/SpinnerModal';
import AuthenticationService from '../../services/Authentication/AuthenticationService';
import User from '../../data/models/User/User';
import UserService from '../../services/User/UserService';
import FirebaseLoggingService from '../../services/Firebase/FirebaseLoggingService';
import { getCurrentTimeStamp } from '../../helpers/inbdeUtils';

class GoogleSignIn extends Component {
  _isMounted = false;
  _isListened = false;
  authenticationService = new AuthenticationService();
  userService = new UserService();

  constructor(props) {
    super(props);

    this.state = { isLoading: false };

    this.authenticateUser = this.authenticateUser.bind(this);
    this.responseGoogleSuccess = this.responseGoogleSuccess.bind(this);
    this.responseGoogleFail = this.responseGoogleFail.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async authenticateUser(response) {
    console.log('Google Sign In response:', response);
    this.authenticationService.signInWithFirebase(response);

    firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        console.log('Google Sign In user:', user);
        if (!this._isListened) {
          this._isListened = true;

          const whitelistEntry = await this.userService.isUserInWhitelist(user.email);

          if (whitelistEntry) {
            this.handleUserLogin(response, user, whitelistEntry);

            this.props.setEmail(user.email);
          } else {
            this._isMounted && this.setState({ isLoading: false });
            toast.error('User does not have permission to access');

            firebase.auth().signOut();
          }
        }
      }
    });
  }

  async handleUserLogin(googleUserData, firebaseUserData, whitelistEntry) {
    const { uid: user_id, email, displayName } = firebaseUserData;
    const currentTimeStamp = getCurrentTimeStamp();

    if (!this.loggingService) {
      this.loggingService = new FirebaseLoggingService({
        userId: user_id,
        userName: displayName,
        email
      });
    }

    const user = await this.userService.getUserDetails(user_id);
    const user_exists = user ? user.exists : false;
    const { access_type: access } = whitelistEntry;

    const googleIdToken = googleUserData.credential;
    if (!user_exists) {
      this.signUpUser(googleIdToken, user, user_id, currentTimeStamp, access);
    } else {
      const userDevice = navigator && navigator.userAgent ? navigator.userAgent : '';
      const data = {
        last_login: currentTimeStamp,
        updated_on: currentTimeStamp,
        is_logged_in: true,
        deleted_on: null,
        deactivated_on: null,
        is_active: 'active',
        access_type: access,
        user_device: userDevice,
        google_id_token: googleIdToken
      };

      const isSuccess = await this.userService.updateUserLogin(firebaseUserData, data);
      if (isSuccess) {
        this.loggingService && this.loggingService.userHasLoggedIn(user_id);
        this._isMounted && this.setState({ isLoading: false });
      }
    }
  }

  responseGoogleFail(error) {
    console.error(error);
    this.loggingService && this.loggingService.logInbdeException(error, 'Google Sign In error');
    toast.error(`Unable to sign in`);
  }

  responseGoogleSuccess(response) {
    this._isMounted = true;
    this._isListened = false;
    this._isMounted && this.setState({ isLoading: true });
    this.authenticateUser(response);
  }

  async signUpUser(googleIdToken, user, user_id, currentTimeStamp, access) {
    const userDevice = navigator && navigator.userAgent ? navigator.userAgent : '';

    let userDetails = (
      <User
        id={user_id}
        full_name={user.displayName}
        given_name={user.displayName}
        email={user.email}
        image_url={user.photoURL}
        access_type={access}
        is_active={'active'}
        created_on={currentTimeStamp}
        deleted_on={null}
        deactivated_on={null}
        last_login={currentTimeStamp}
        updated_on={currentTimeStamp}
        isLoggedIn={true}
        user_device={userDevice}
        google_id_token={googleIdToken}
      />
    );

    const isSuccess = await this.userService.createUser(userDetails);
    if (isSuccess) {
      this.loggingService && this.loggingService.userHasSignedUp(user_id);
      this._isMounted && this.setState({ isLoading: false });
    }
  }

  render() {
    const { isLoading } = this.state;

    return (
      <div>
        {isLoading && <SpinnerModal />}
        <GoogleLogin
          clientId={application_properties.client_id}
          render={renderProps => (
            <Button
              color="outline-nyu"
              size="sm"
              block
              className="mt-2"
              onClick={renderProps.onClick}
              disabled={renderProps.disabled}
            >
              <img className="mr-2 fs-0" src={logo} alt="NYU Dentistry" width={40} /> Login with NYU
            </Button>
          )}
          onSuccess={this.responseGoogleSuccess}
          onFailure={this.responseGoogleFail}
          cookiePolicy={'single_host_origin'}
          prompt={'select_account'}
        />
      </div>
    );
  }
}

GoogleSignIn.propTypes = {
  setEmail: PropTypes.func.isRequired
};

export default GoogleSignIn;
